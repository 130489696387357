import { Link } from 'gatsby';
import React from 'react';
import AlternativeLayout from '../components/layout/AlternativeLayout';
import SplitSection from '../components/SplitSection';
import NotFound from '../svg/NotFound';

const FourOFour = () => {
  return (
    <AlternativeLayout>
      <SplitSection
        secondarySlot={
          <div className="lg:text-left">
            <h1 className="text-4xl l font-bold leading-none">
              Hier stimmt was nicht! <br />
            </h1>
            <p className="text-l">
              <br />
              Leider konnten wir die von dir angeforderte Seite nicht finden...
              <br />
              <br />
              <Link to="/">zurück zur Startseite</Link>
            </p>
          </div>
        }
        primarySlot={<NotFound />}
      />
    </AlternativeLayout>
  );
};

export default FourOFour;
