import React from 'react';

const NotFound = () => (
  <svg viewBox="0 0 1075 585" width="400px" height="400px">
    <defs>
      <path
        d="M1067.74 549.09c0 19.28-236.08 34.91-527.29 34.91-291.22 0-527.3-15.63-527.3-34.91 0-19.28 236.08-34.91 527.3-34.91 291.21 0 527.29 15.63 527.29 34.91z"
        id="a"
      />
      <path
        d="M372.64 350.67c0 145.75 61.69 184.34 147.6 184.34S671.5 492.75 671.5 350.67c0-151.88-65.35-183.73-151.26-183.73s-147.6 55.12-147.6 183.73zm57.57.09c0-89.07 32.36-136.05 91.86-136.05 59.51 0 91.87 30.86 91.87 136.05 0 98.41-33.64 135.87-93.14 135.87s-90.59-34.91-90.59-135.87z"
        id="b"
      />
      <path
        d="M285.68 433.35v-104.6c0-11.57-9.38-20.95-20.95-20.95h-8.32c-11.57 0-20.95 9.38-20.95 20.95v104.6H135.27c-6.6 0-11.96-5.36-11.96-11.96 0-1.95.48-3.87 1.39-5.59 10.6-20.07 95.43-180.63 106.04-200.7 5.4-10.23 1.49-22.9-8.74-28.31-.27-.14-.55-.28-.84-.42-.62-.29-5.63-2.66-6.26-2.95-10.09-4.77-22.15-.78-27.42 9.06-12.93 24.2-116.39 217.75-129.33 241.94a28.43 28.43 0 00-3.35 13.39c0 15.69 12.72 28.4 28.4 28.4h152.26v66.15c0 13.86 11.24 25.1 25.11 25.1s25.11-11.24 25.11-25.1v-66.15h35.52c11.84 0 21.44-9.59 21.44-21.43s-9.6-21.43-21.44-21.43h-35.52z"
        id="c"
      />
      <path
        d="M943.42 433.35v-104.6c0-11.57-9.38-20.95-20.95-20.95h-8.32c-11.57 0-20.95 9.38-20.95 20.95v104.6H793.01c-6.61 0-11.96-5.36-11.96-11.96 0-1.95.48-3.87 1.38-5.59 10.61-20.07 95.44-180.63 106.05-200.7 5.4-10.23 1.49-22.9-8.74-28.31l-.84-.42c-.63-.29-5.64-2.66-6.26-2.95-10.1-4.77-22.16-.78-27.42 9.06-12.93 24.2-116.4 217.75-129.33 241.94a28.43 28.43 0 00-3.35 13.39c0 15.69 12.71 28.4 28.4 28.4H893.2v66.15c0 13.86 11.24 25.1 25.11 25.1 13.86 0 25.11-11.24 25.11-25.1v-66.15h35.52c11.83 0 21.43-9.59 21.43-21.43s-9.6-21.43-21.43-21.43h-35.52z"
        id="d"
      />
      <path
        d="M295.48 421.1V316.5c0-11.57-9.38-20.95-20.95-20.95h-8.32c-11.57 0-20.95 9.38-20.95 20.95v104.6H145.07c-6.6 0-11.96-5.36-11.96-11.96 0-1.95.48-3.87 1.39-5.59 10.6-20.07 95.43-180.63 106.04-200.7 5.4-10.23 1.49-22.9-8.74-28.31l-.84-.42c-.63-.29-5.63-2.66-6.26-2.95-10.09-4.77-22.15-.78-27.42 9.06-12.93 24.2-116.39 217.75-129.33 241.94a28.43 28.43 0 00-3.35 13.39c0 15.69 12.71 28.41 28.4 28.41h152.26v66.14c0 13.86 11.24 25.11 25.11 25.11s25.11-11.25 25.11-25.11v-66.14H331c11.84 0 21.43-9.6 21.43-21.44 0-11.84-9.59-21.43-21.43-21.43h-35.52z"
        id="e"
      />
      <path
        d="M961.79 421.1V316.5c0-11.57-9.38-20.95-20.95-20.95h-8.32c-11.57 0-20.95 9.38-20.95 20.95v104.6H811.38c-6.6 0-11.96-5.36-11.96-11.96 0-1.95.48-3.87 1.39-5.59 10.6-20.07 95.43-180.63 106.04-200.7 5.4-10.23 1.49-22.9-8.74-28.31l-.84-.42c-.62-.29-5.63-2.66-6.26-2.95-10.09-4.77-22.15-.78-27.42 9.06-12.93 24.2-116.39 217.75-129.33 241.94a28.43 28.43 0 00-3.35 13.39c0 15.69 12.72 28.41 28.4 28.41h152.26v66.14c0 13.86 11.24 25.11 25.11 25.11s25.11-11.25 25.11-25.11v-66.14h35.52c11.84 0 21.43-9.6 21.43-21.44 0-11.84-9.59-21.43-21.43-21.43h-35.52z"
        id="f"
      />
      <path
        d="M392.24 339.65c0 145.75 61.68 184.33 147.59 184.33S691.1 481.73 691.1 339.65c0-151.88-65.36-183.73-151.27-183.73-85.91 0-147.59 55.12-147.59 183.73zm57.57.09c0-89.08 32.36-136.05 91.86-136.05s91.86 30.85 91.86 136.05c0 98.41-33.63 135.86-93.13 135.86-59.5 0-90.59-34.91-90.59-135.86z"
        id="g"
      />
      <path
        d="M470.97 5.35c-7.12 0-12.89 5.77-12.89 12.89 0 7.13 5.77 12.9 12.89 12.9 7.12 0 12.9-5.77 12.9-12.9 0-7.12-5.78-12.89-12.9-12.89z"
        id="h"
      />
      <path
        d="M538.6 46.87c0 25.88-16.2 46.86-36.17 46.86-19.98 0-36.18-20.98-36.18-46.86 0-25.89 16.2-46.87 36.18-46.87 19.97 0 36.17 20.98 36.17 46.87z"
        id="i"
      />
      <path
        d="M466.66 39.79c-.27 2.35-.41 4.71-.41 7.08 0 25.88 16.2 46.86 36.18 46.86 19.97 0 36.17-20.98 36.17-46.86 0-2.37-.14-4.73-.41-7.08-2.64 22.52-17.64 39.79-35.76 39.79-18.13 0-33.13-17.27-35.77-39.79z"
        id="j"
      />
      <path
        d="M568.2 191.65c-7.8 3.76-17.42 4.88-26.75 5.25-2.38.15-4.77.1-7.15-.13-4.59-.44-8.91-2.43-12.24-5.63-.78-.5-1-1.55-.49-2.32.14-.21.32-.39.53-.52 4.97-5.46 13.26-8.8 21.61-11.21 6.33-1.83 17.37-6.56 24.28-5.92 6.69.62 9.02 8.92 8.04 12.95-.78 3.19-4.13 5.74-7.83 7.53z"
        id="k"
      />
      <path
        d="M568.2 191.65c-7.8 3.76-17.42 4.88-26.75 5.25-2.38.15-4.77.1-7.15-.13-4.59-.44-8.91-2.43-12.24-5.63-.78-.5-1-1.55-.49-2.32.14-.21.32-.39.53-.52 4.97-5.46 13.26-8.8 21.61-11.21 6.33-1.83 17.37-6.56 24.28-5.92 6.69.62 9.02 8.92 8.04 12.95-.78 3.19-4.13 5.74-7.83 7.53z"
        id="l"
      />
      <path
        d="M475.67 175.33a26.38 26.38 0 008.83 12.49c10.09 8.09 23.98 9.2 36.91 9.09 11.33-.1 22.65-.88 33.89-2.33 3.77-.33 7.47-1.19 11-2.55 4.22-1.82 7.93-5.17 9.43-9.52s.44-9.67-3.13-12.57c-3.14-2.55-7.46-2.9-11.5-3.14-2.3-.14-20.77-1.25-23.07-1.39 2.28-2.31 5.31-3.61 8.09-5.29 3.68-2.22 6.13-5.96 8.81-9.33 4.13-4.93 8.62-9.55 13.43-13.81.75 4.31 3.48 7.85 6.69 10.82 3.2 2.97 6.9 5.37 10.24 8.2 8.07 6.85 13.89 16.16 22.38 22.49 5.84-1.54 11.3-4.26 16.04-8.01l-3.3-5.98c-.27-.62-.7-1.15-1.24-1.55-.94-.58-2.14-.28-3.23-.44-2.9-.44-4.16-3.79-5.3-6.49-3.12-7.42-9.11-13.17-14.21-19.4a97.141 97.141 0 01-9.99-14.71c-1.72-3.1-3.29-6.33-5.62-9s-5.59-4.77-9.13-4.78c-3.14-.01-6.02 1.58-8.72 3.18a233.399 233.399 0 00-23.62 16.09c-3.94 3.06-7.86 6.3-12.48 8.17-1.95.79-4 1.33-5.87 2.3-3.27 1.68-5.75 4.53-8.43 7.06-9.53 9.02-22.58 14.86-35.7 15.33a19.779 19.779 0 00-1.2 15.07z"
        id="m"
      />
      <path
        d="M523.4 104.09a10.07 10.07 0 01-5.77 5.57c-1.83.49-3.76.55-5.62.18-1.63-.17-3.19-.7-4.58-1.56-2.16-1.48-3.24-4.14-3.64-6.74-.39-2.59-.21-5.24-.47-7.86-.24-2.11-.64-4.2-1.2-6.25l-.51-2.04c-.17-.68-.33-1.36-.48-2.04-.14-.64-.27-1.28-.38-1.93-.09-.54-.17-1.09-.23-1.64-.06-1.63-.33-3.25-.81-4.81-.45-1.01-1.02-1.97-1.69-2.85-.28-.41-.56-.82-.81-1.24a5.946 5.946 0 01-.98-3.75c.04-.2.08-.4.15-.6.03-.07.06-.15.09-.22.57-.16 1.03-.59 1.23-1.15.11-.6.05-1.21-.15-1.78-.24-.79-.56-1.56-.96-2.28-.26-.48-1.16-1.34-1.17-1.89-.01-1.1 2.14-1.99 3.01-2.42 2.66-1.34 5.63-1.94 8.6-1.75 1.9.13 3.96.72 5.01 2.31.95 1.44.82 3.3 1.22 4.97.05.25.15.47.3.67.21.18.47.3.74.34.47.09.95.11 1.42.05.13-.01.25-.03.37-.05.74-.14 1.43-.44 2.03-.88.59-.15 1.07-.23 1.18-.25.02 0 .04-.01.05-.01 1.58 4.97 1.78 10.3 3.07 15.37.64 2.52 1.55 5 1.68 7.59.01.12.02.23.02.35.02.83-.05 1.67-.01 2.5.01.38.05.75.12 1.12.14.59.32 1.17.53 1.74l.01.03c.06.18.11.37.16.55.03.14.07.27.09.41.24 1.39.27 2.81.11 4.21-.11 2.76-.7 5.47-1.73 8.03z"
        id="n"
      />
      <path
        d="M503.05 26.42c-10.25 0-18.55 8.31-18.55 18.56 0 10.25 8.3 18.56 18.55 18.56s18.56-8.31 18.56-18.56c0-10.25-8.31-18.56-18.56-18.56z"
        id="o"
      />
      <path
        d="M615.64 131.99c.72 4.3 1.3 7.73 1.45 8.59-1.23.35-2.55-.05-3.37-1.04-.78-.97-1.38-2.08-1.76-3.27a67.248 67.248 0 00-8.21-14.73c-11.81-.99-23.49-5.88-33.88-11.59-10.39-5.71-20.12-12.55-30.37-18.5a43.877 43.877 0 00-5.55-2.83c-3.2-1.29-6.81-1.95-9.85-3.47-.2-.1-.41-.2-.61-.31-.68-.38-1.34-.81-1.94-1.31-.33-.26-.64-.55-.93-.85a20.461 20.461 0 01-3.56-5.95c-1.63-3.62-2.21-7.97-3.84-11.58.75-.7 1.59-1.3 2.5-1.77.13-.07.26-.13.4-.2.64-.3 1.32-.54 2-.73.59-.15 1.07-.23 1.18-.25.02 0 .04-.01.05-.01 1.84-.3 3.72-.15 5.49.43 2.58.88 4.76 2.62 6.94 4.27 3.53 2.49 6.92 5.18 10.14 8.05 1.79 1.72 3.43 3.6 5.36 5.16 1.6 1.24 3.29 2.36 5.05 3.35 15.65 9.33 28.18 16.78 31.31 18.65 3.53 2.1 7.06 4.2 10.52 6.43 5.24 3.39 10.3 7.05 15.36 10.72 4.46 3.23 8.04 5.83 8.93 6.47.98.63 1.83 1.42 2.53 2.34.62 1.05 1 2.22 1.12 3.44.75 4.89.33 9.89-1.23 14.59-.53-5.06-2.33-9.91-5.23-14.1z"
        id="p"
      />
      <path
        d="M519.84 123.96c-3.69 6.12-2.19 13.23-.8 20.24-3.79 3.27-6.83 5.89-7.59 6.54-1.4 1.2-2.79 2.41-4.3 3.47a58.062 58.062 0 01-6.85 3.93c-3.36 1.73-6.05 3.11-6.72 3.46a32.04 32.04 0 01-6.58 2.79c-4.41 1.13-9.1.34-13.47-.97a6.09 6.09 0 01-1.47-.61c-.27-.16-.51-.37-.7-.63-.43-.77-.58-1.66-.44-2.54.44-7.34 2.24-14.54 4.03-21.68.96-4.28 2.24-8.48 3.85-12.57 1.57-3.5 3.38-6.89 5.41-10.15 2.24-4.09 4.9-7.95 7.93-11.51 1.47-1.36 2.71-2.96 3.67-4.73 1.12-2.55.69-5.47.91-8.25.18-2.08.73-4.12 1.63-6 .49-1.02 1.07-1.98 1.75-2.89.46 1.07.82 1.92.91 2.13l.6 1.4c.39.89.69 1.61.77 1.78 1.1 2.56 1.97 4.6 2.19 5.12.79 2.14 1.92 4.15 3.34 5.94a6.49 6.49 0 009.05 1.55 6.43 6.43 0 002.07-2.41c1.05-1.87 1.82-3.9 2.27-6.01.62-2.56.7-5.23.25-7.83-.76-5.01-2.89-10.12-4.44-14.77-.63-1.55-.99-3.2-1.07-4.87 0-.19.02-.38.05-.56l.03-.15c.64-.3 1.32-.54 2-.73.59-.15 1.07-.23 1.18-.25 2.05 11.06 3.69 19.9 4.1 22.12.03.17.06.34.09.52.2 1.05.4 2.11.62 3.16.19.96.41 1.91.65 2.86l.01.03c.08.32.16.64.25.96.25.87.52 1.73.82 2.58 1.85 5.12 5.08 10.27 3.89 15.59-1.25 5.61-6.93 9-9.89 13.94z"
        id="q"
      />
      <path
        d="M605.65 192.06c1.17 2.12 3.95 2.61 6.36 2.8 14.09 1.16 28.26.73 42.25-1.28.76-.06 1.5-.28 2.15-.67 1.99-1.4.77-4.79-1.41-5.88-2.17-1.09-4.74-.83-7.13-1.3-2.37-.6-4.62-1.57-6.67-2.89-7.25-4.17-12.86-7.82-14.29-8.69-2.79-1.79-6.09-3.71-9.24-2.64-8.52 2.88-12.68 8.22-12.63 17.41-.05 1.08.16 2.16.61 3.14z"
        id="r"
      />
      <path
        d="M501.2 82.96c-.02.13-.04.26-.07.39l-.12.64c-.39 2-.87 3.99-1.42 5.96-1.51 5.46-3.53 10.83-5.53 16.12-8.08 21.28-14.55 38.31-16.16 42.56-1.36 3.58-2.72 7.15-4.26 10.65-.52 1.17-1.05 2.35-1.58 3.53-1.89 4.26-3.71 8.65-4.31 13.18-.57 2.98-.63 6.04-.19 9.04.6 2.97 2.4 5.87 5.22 6.97 1.38.54 3.35 1.26 2.98 2.69-.21.78-1.1 1.17-1.9 1.3a7.33 7.33 0 01-5.97-1.72c-1.5-1.34-2.66-3.41-4.67-3.64-1.38-.15-2.66.68-3.93 1.26-5.18 2.37-11.22.71-16.67-.97.43-2.2 2.64-3.66 4.84-4.1 2.2-.44 4.48-.13 6.72-.19.72.04 1.43-.14 2.04-.52.55-.5.93-1.16 1.1-1.88 1.44-4.28 2.52-8.68 3.21-13.14.73-4.67 1.03-9.4 2.06-14.01.8-3.6 2.04-7.08 3.1-10.61a222.37 222.37 0 005.06-22.07c.67-3.09 1.14-6.21 1.43-9.36.18-2.61.09-5.23.34-7.84.42-3.38 1.13-6.72 2.11-9.99 1.45-5.34 2.6-9.62 2.89-10.68 1.42-5.27 2.95-10.75 6.56-14.84 2.54-2.91 6.17-4.65 10.03-4.79.71 0 1.43.08 2.12.23.12.03.24.06.35.09.77.2 1.5.5 2.18.9 1.11-.71 1.68-.46 2.42.63.64 1.16.95 2.47.88 3.8.06 3.49-.23 6.97-.86 10.41z"
        id="s"
      />
      <path
        d="M502.43 87.13l-.05.04-.26.26-2.53 2.52-1.24-5.2c-2.54-10.71-3.96-16.66-4.24-17.85.71 0 1.43.08 2.12.23.04-.2.08-.4.15-.6.02.05.08.28.2.69l1.44 4.9 2.73 9.3.45 1.54 1.23 4.17z"
        id="t"
      />
      <path
        d="M514.77 39.08c-.42.43-.87.82-1.36 1.18a12.475 12.475 0 01-7.53 2.52h-15.09c-6.95-.01-12.58-5.65-12.56-12.6.01-6.94 5.63-12.56 12.56-12.57h15.09c6.94.02 12.57 5.64 12.59 12.58 0 3.34-1.33 6.54-3.7 8.89z"
        id="u"
      />
      <path
        d="M532.62 5.35c-7.12 0-12.9 5.77-12.9 12.89 0 7.13 5.78 12.9 12.9 12.9s12.9-5.77 12.9-12.9c0-7.12-5.78-12.89-12.9-12.89z"
        id="v"
      />
      <path
        d="M531.83 5.36c7.12.26 12.68 6.24 12.42 13.35-.25 6.75-5.66 12.17-12.42 12.42.16 0 .32.01.48.01 7.12 0 12.89-5.77 12.89-12.9 0-7.12-5.77-12.89-12.89-12.89-.16 0-.32 0-.48.01z"
        id="w"
      />
      <path
        d="M471.44 5.36c-7.11.26-12.67 6.24-12.41 13.35.24 6.75 5.66 12.17 12.41 12.42-.15 0-.31.01-.47.01-7.12-.01-12.89-5.79-12.88-12.91 0-7.11 5.77-12.88 12.88-12.88.16 0 .32 0 .47.01z"
        id="x"
      />
      <path
        d="M513.41 40.26a12.475 12.475 0 01-7.53 2.52h-15.09c-2.72 0-5.36-.88-7.53-2.52h30.15z"
        id="y"
      />
      <path
        d="M610.86 133.7s3.25 9.37 6.23 6.88c-.16-.82-.95-4.9-2.37-12.25-2.06 2.87-3.35 4.66-3.86 5.37z"
        id="z"
      />
      <path
        d="M1033.14 406.94c3.93-10.8-1.64-22.74-12.44-26.66a20.873 20.873 0 00-11.45-.8c.04.72.21 4.29.52 10.71-3.04-5.5-4.73-8.56-5.06-9.17a20.7 20.7 0 00-10.68 11.71c-.66 1.81-1.07 3.71-1.2 5.63-.66 9.27 4.92 17.85 13.65 21.02 10.8 3.93 22.74-1.64 26.66-12.44z"
        id="A"
      />
      <path
        d="M1031.55 543.58c-1.79-9.11 5.96-17.18 13.62-22.43 7.65-5.25 16.59-10.4 19.21-19.31 3.75-12.8-7.43-24.52-16.13-34.63-6.46-7.5-12-15.74-16.52-24.54-1.81-3.54-3.48-7.23-3.95-11.17-.69-5.67 1.13-11.31 2.97-16.73 6.12-18.02 12.66-35.9 19.62-53.63"
        id="B"
      />
      <path
        d="M1034 404.18c3.92-10.8-1.65-22.73-12.45-26.66a20.742 20.742 0 00-11.44-.79c.03.71.21 4.28.52 10.71-3.04-5.51-4.73-8.57-5.07-9.18a20.722 20.722 0 00-10.68 11.72c-.66 1.81-1.06 3.7-1.19 5.62a20.8 20.8 0 0013.65 21.03c10.8 3.92 22.73-1.65 26.66-12.45z"
        id="C"
      />
      <path
        d="M1043.98 344.25c.11.68.69 4.08 1.71 10.18 1.91-6.41 2.97-9.97 3.18-10.69 11.49.17 20.66 9.62 20.49 21.11-.17 11.49-9.62 20.66-21.11 20.49-11.48-.17-20.66-9.62-20.49-21.11.04-2.31.46-4.61 1.25-6.78a20.777 20.777 0 0114.97-13.2z"
        id="D"
      />
      <path
        d="M1045.12 340.58c.12.68.69 4.07 1.72 10.18 1.9-6.42 2.96-9.98 3.17-10.69 11.49.17 20.66 9.62 20.49 21.1-.17 11.49-9.62 20.67-21.11 20.5-11.48-.17-20.66-9.62-20.49-21.11.04-2.32.46-4.61 1.25-6.79a20.765 20.765 0 0114.97-13.19z"
        id="E"
      />
      <path
        d="M1071.24 454.96c3.3-9.1-.11-19.28-8.23-24.55-.17.55-.98 3.29-2.44 8.22-1.04-6.18-1.62-9.62-1.73-10.3-.02-.01-.04-.02-.06-.02-10.8-3.93-22.73 1.65-26.66 12.44-3.92 10.8 1.65 22.74 12.45 26.66.01 0 .02.01.02.01 10.8 3.92 22.73-1.66 26.65-12.46z"
        id="F"
      />
      <path
        d="M1072.23 451.23c3.3-9.1-.11-19.27-8.23-24.54-.17.55-.98 3.28-2.44 8.21-1.04-6.18-1.62-9.61-1.74-10.3-.01 0-.03-.01-.05-.02-10.8-3.92-22.73 1.65-26.66 12.45-3.92 10.8 1.65 22.73 12.45 26.66.01 0 .02 0 .02.01 10.8 3.91 22.73-1.67 26.65-12.47z"
        id="G"
      />
      <path
        d="M1047.01 518.85c11.31-1.99 18.87-12.77 16.88-24.08-1.98-11.31-12.76-18.87-24.07-16.88-5.12.9-9.71 3.68-12.88 7.79.62.93 3.71 5.58 9.27 13.95-7.6-4.44-11.82-6.91-12.66-7.41-.98 3.16-1.19 6.5-.61 9.75 1.98 11.31 12.76 18.87 24.07 16.88z"
        id="H"
      />
      <path
        d="M1047.87 516.09c11.31-1.99 18.87-12.77 16.88-24.08-1.99-11.31-12.77-18.86-24.08-16.88-5.11.9-9.7 3.68-12.87 7.79.62.93 3.71 5.58 9.27 13.95-7.6-4.44-11.82-6.91-12.66-7.4-.99 3.15-1.2 6.49-.62 9.74 1.99 11.31 12.77 18.87 24.08 16.88z"
        id="I"
      />
      <path
        d="M26.54 461.89c2.58-7.1-1.08-14.94-8.18-17.52-2.41-.88-5.01-1.06-7.52-.52.02.47.14 2.81.34 7.03-1.99-3.61-3.1-5.62-3.33-6.03a13.64 13.64 0 00-7.01 7.7c-.44 1.19-.7 2.44-.79 3.7a13.664 13.664 0 008.97 13.81c7.1 2.58 14.94-1.08 17.52-8.17z"
        id="J"
      />
      <path
        d="M25.49 551.66c-1.17-5.98 3.92-11.29 8.95-14.73 5.02-3.45 10.9-6.84 12.62-12.69 2.46-8.41-4.88-16.11-10.6-22.75a82.416 82.416 0 01-10.85-16.13 22.308 22.308 0 01-2.6-7.33c-.45-3.73.75-7.44 1.96-11a776.75 776.75 0 0112.89-35.23"
        id="K"
      />
      <path
        d="M27.1 460.08c2.58-7.1-1.08-14.94-8.18-17.52-2.4-.88-5.01-1.06-7.52-.52.03.47.14 2.81.35 7.03l-3.33-6.03a13.658 13.658 0 00-7.02 7.7c-.43 1.19-.7 2.44-.79 3.7a13.664 13.664 0 008.97 13.81c7.1 2.58 14.94-1.08 17.52-8.17z"
        id="L"
      />
      <path
        d="M33.66 420.7c.07.45.45 2.68 1.12 6.69 1.25-4.21 1.95-6.56 2.09-7.02 7.55.11 13.57 6.32 13.46 13.86-.11 7.55-6.32 13.58-13.87 13.47-7.54-.12-13.57-6.32-13.46-13.87.02-1.52.3-3.03.82-4.46 1.59-4.38 5.29-7.65 9.84-8.67z"
        id="M"
      />
      <path
        d="M34.41 418.29c.07.44.45 2.67 1.12 6.68 1.26-4.21 1.95-6.55 2.09-7.02 7.55.11 13.57 6.32 13.46 13.87-.11 7.55-6.32 13.57-13.87 13.46-7.54-.11-13.57-6.32-13.46-13.87a13.668 13.668 0 0110.66-13.12z"
        id="N"
      />
      <path
        d="M51.57 493.44c2.17-5.98-.08-12.67-5.41-16.13-.11.36-.64 2.16-1.61 5.4-.68-4.06-1.06-6.32-1.13-6.77-.01-.01-.03-.01-.04-.01-7.09-2.58-14.93 1.08-17.51 8.17-2.58 7.09 1.08 14.94 8.17 17.52h.02c7.1 2.58 14.94-1.09 17.51-8.18z"
        id="O"
      />
      <path
        d="M52.22 490.99c2.17-5.98-.07-12.67-5.41-16.13-.11.36-.64 2.16-1.61 5.4-.68-4.06-1.06-6.32-1.13-6.77-.01 0-.03-.01-.04-.01-7.09-2.57-14.93 1.09-17.51 8.19-2.57 7.09 1.09 14.93 8.19 17.51 7.1 2.57 14.94-1.09 17.51-8.19z"
        id="P"
      />
      <path
        d="M35.65 535.41c7.43-1.3 12.4-8.39 11.09-15.82-1.3-7.43-8.39-12.39-15.82-11.09-3.36.59-6.37 2.42-8.46 5.12.41.61 2.44 3.67 6.09 9.17-4.99-2.92-7.76-4.55-8.32-4.87-.64 2.07-.78 4.27-.4 6.4 1.31 7.43 8.39 12.4 15.82 11.09z"
        id="Q"
      />
      <path
        d="M36.21 533.6c7.43-1.3 12.4-8.39 11.09-15.82-1.3-7.43-8.38-12.39-15.82-11.09-3.35.59-6.37 2.42-8.45 5.12.4.61 2.43 3.66 6.09 9.16-5-2.92-7.77-4.54-8.32-4.86-.65 2.07-.79 4.27-.41 6.4 1.31 7.43 8.39 12.4 15.82 11.09z"
        id="R"
      />
      <path
        d="M497.77 318.99c0 12.14-7.61 21.99-17 21.99-9.38 0-16.99-9.85-16.99-21.99 0-12.15 7.61-21.99 16.99-21.99 9.39 0 17 9.84 17 21.99z"
        id="S"
      />
      <path
        d="M590.23 319.49c0 12.14-7.61 21.99-16.99 21.99-9.39 0-17-9.85-17-21.99 0-12.15 7.61-21.99 17-21.99 9.38 0 16.99 9.84 16.99 21.99z"
        id="T"
      />
      <path
        d="M529.25 402.46c-17.11 0-30.98-8.06-30.98-18 0-8.6 10.4-15.8 24.31-17.56 2.21-.29 4.44-.44 6.67-.43 3.74-.01 7.48.39 11.13 1.2 11.61 2.59 19.86 9.14 19.86 16.79 0 9.94-13.87 18-30.99 18z"
        id="U"
      />
      <path
        d="M501.76 313.99c0 12.15-7.6 21.99-16.99 21.99-9.38 0-16.99-9.84-16.99-21.99 0-12.14 7.61-21.99 16.99-21.99 9.39 0 16.99 9.85 16.99 21.99z"
        id="V"
      />
      <path
        d="M594.23 314.49c0 12.15-7.61 21.99-17 21.99-9.38 0-16.99-9.84-16.99-21.99 0-12.14 7.61-21.99 16.99-21.99 9.39 0 17 9.85 17 21.99z"
        id="W"
      />
      <path
        d="M564.24 379.47c0 9.93-13.87 17.99-30.99 17.99-17.11 0-30.99-8.06-30.99-17.99 0-9.94 13.88-18 30.99-18 17.12 0 30.99 8.06 30.99 18z"
        id="X"
      />
      <path
        d="M539.43 372.56a4.37 4.37 0 01-3.18 1.41h-10a4.66 4.66 0 01-4.49-4.8c-.01-.98.28-1.93.82-2.74 2.21-.31 4.44-.47 6.67-.46 3.75-.01 7.48.42 11.13 1.28.25.61.37 1.26.37 1.92.01 1.26-.47 2.47-1.32 3.39z"
        id="Y"
      />
      <path
        d="M484.77 303c-2.76 0-5 2.23-5 4.99 0 2.76 2.24 5 5 5s5-2.24 5-5-2.24-4.99-5-4.99z"
        id="Z"
      />
      <path
        d="M577.73 303c-2.76 0-4.99 2.23-4.99 4.99 0 2.76 2.23 5 4.99 5 2.76 0 5-2.24 5-5s-2.24-4.99-5-4.99z"
        id="aa"
      />
      <path d="M582.73 349.98a4.995 4.995 0 100 9.99 4.995 4.995 0 100-9.99z" id="ab" />
      <path
        d="M461.26 349.98c-2.77 0-5 2.23-5 5 0 2.76 2.23 4.99 5 4.99 2.76 0 4.99-2.23 4.99-4.99 0-2.77-2.23-5-4.99-5z"
        id="ac"
      />
    </defs>
    <use xlinkHref="#a" fill="#f2f2f2" />
    <use xlinkHref="#a" fillOpacity={0} stroke="#000" strokeOpacity={0} />
    <use xlinkHref="#b" fill="#2f2e41" />
    <use xlinkHref="#b" fillOpacity={0} stroke="#000" strokeOpacity={0} />
    <use xlinkHref="#c" fill="#991a5f" />
    <use xlinkHref="#c" fillOpacity={0} stroke="#000" strokeOpacity={0} />
    <use xlinkHref="#d" fill="#991a5f" />
    <use xlinkHref="#d" fillOpacity={0} stroke="#000" strokeOpacity={0} />
    <use xlinkHref="#e" fillOpacity={0} />
    <use xlinkHref="#e" fillOpacity={0} stroke="#3f3d56" />
    <use xlinkHref="#f" fillOpacity={0} />
    <use xlinkHref="#f" fillOpacity={0} stroke="#3f3d56" />
    <use xlinkHref="#g" fillOpacity={0} />
    <use xlinkHref="#g" fillOpacity={0} stroke="#3f3d56" />
    <use xlinkHref="#h" fill="#2f2e41" />
    <use xlinkHref="#h" fillOpacity={0} stroke="#000" strokeOpacity={0} />
    <use xlinkHref="#i" fill="#2f2e41" />
    <use xlinkHref="#i" fillOpacity={0} stroke="#000" strokeOpacity={0} />
    <use xlinkHref="#j" />
    <use xlinkHref="#j" fillOpacity={0} stroke="#000" strokeOpacity={0} />
    <use xlinkHref="#k" fill="#3f3d56" />
    <use xlinkHref="#k" fillOpacity={0} stroke="#000" strokeOpacity={0} />
    <use xlinkHref="#l" />
    <use xlinkHref="#l" fillOpacity={0} stroke="#000" strokeOpacity={0} />
    <use xlinkHref="#m" fill="#3f3d56" />
    <use xlinkHref="#m" fillOpacity={0} stroke="#000" strokeOpacity={0} />
    <use xlinkHref="#n" fill="#fbbebe" />
    <use xlinkHref="#n" fillOpacity={0} stroke="#000" strokeOpacity={0} />
    <use xlinkHref="#o" fill="#fbbebe" />
    <use xlinkHref="#o" fillOpacity={0} stroke="#000" strokeOpacity={0} />
    <use xlinkHref="#p" fill="#fbbebe" />
    <use xlinkHref="#p" fillOpacity={0} stroke="#000" strokeOpacity={0} />
    <use xlinkHref="#q" fill="#991a5f" />
    <use xlinkHref="#q" fillOpacity={0} stroke="#000" strokeOpacity={0} />
    <use xlinkHref="#r" fill="#3f3d56" />
    <use xlinkHref="#r" fillOpacity={0} stroke="#000" strokeOpacity={0} />
    <use xlinkHref="#s" fill="#fbbebe" />
    <use xlinkHref="#s" fillOpacity={0} stroke="#000" strokeOpacity={0} />
    <use xlinkHref="#t" fill="#991a5f" />
    <use xlinkHref="#t" fillOpacity={0} stroke="#000" strokeOpacity={0} />
    <use xlinkHref="#u" fill="#2f2e41" />
    <use xlinkHref="#u" fillOpacity={0} stroke="#000" strokeOpacity={0} />
    <use xlinkHref="#v" fill="#2f2e41" />
    <use xlinkHref="#v" fillOpacity={0} stroke="#000" strokeOpacity={0} />
    <use xlinkHref="#w" />
    <use xlinkHref="#w" fillOpacity={0} stroke="#000" strokeOpacity={0} />
    <use xlinkHref="#x" />
    <use xlinkHref="#x" fillOpacity={0} stroke="#000" strokeOpacity={0} />
    <use xlinkHref="#y" />
    <use xlinkHref="#y" fillOpacity={0} stroke="#000" strokeOpacity={0} />
    <use xlinkHref="#z" fill="#fbbebe" />
    <use xlinkHref="#z" fillOpacity={0} stroke="#000" strokeOpacity={0} />
    <use xlinkHref="#A" fill="#57b894" />
    <use xlinkHref="#A" fillOpacity={0} stroke="#000" strokeOpacity={0} />
    <use xlinkHref="#B" fillOpacity={0} />
    <use xlinkHref="#B" fillOpacity={0} stroke="#3f3d56" />
    <g>
      <use xlinkHref="#C" fillOpacity={0} />
      <use xlinkHref="#C" fillOpacity={0} stroke="#3f3d56" />
    </g>
    <g>
      <use xlinkHref="#D" fill="#57b894" />
      <use xlinkHref="#D" fillOpacity={0} stroke="#000" strokeOpacity={0} />
    </g>
    <g>
      <use xlinkHref="#E" fillOpacity={0} />
      <use xlinkHref="#E" fillOpacity={0} stroke="#3f3d56" />
    </g>
    <g>
      <use xlinkHref="#F" fill="#57b894" />
      <use xlinkHref="#F" fillOpacity={0} stroke="#000" strokeOpacity={0} />
    </g>
    <g>
      <use xlinkHref="#G" fillOpacity={0} />
      <use xlinkHref="#G" fillOpacity={0} stroke="#3f3d56" />
    </g>
    <g>
      <use xlinkHref="#H" fill="#57b894" />
      <use xlinkHref="#H" fillOpacity={0} stroke="#000" strokeOpacity={0} />
    </g>
    <g>
      <use xlinkHref="#I" fillOpacity={0} />
      <use xlinkHref="#I" fillOpacity={0} stroke="#3f3d56" />
    </g>
    <g>
      <use xlinkHref="#J" fill="#57b894" />
      <use xlinkHref="#J" fillOpacity={0} stroke="#000" strokeOpacity={0} />
    </g>
    <g>
      <use xlinkHref="#K" fillOpacity={0} />
      <use xlinkHref="#K" fillOpacity={0} stroke="#3f3d56" />
    </g>
    <g>
      <use xlinkHref="#L" fillOpacity={0} />
      <use xlinkHref="#L" fillOpacity={0} stroke="#3f3d56" />
    </g>
    <g>
      <use xlinkHref="#M" fill="#57b894" />
      <use xlinkHref="#M" fillOpacity={0} stroke="#000" strokeOpacity={0} />
    </g>
    <g>
      <use xlinkHref="#N" fillOpacity={0} />
      <use xlinkHref="#N" fillOpacity={0} stroke="#3f3d56" />
    </g>
    <g>
      <use xlinkHref="#O" fill="#57b894" />
      <use xlinkHref="#O" fillOpacity={0} stroke="#000" strokeOpacity={0} />
    </g>
    <g>
      <use xlinkHref="#P" fillOpacity={0} />
      <use xlinkHref="#P" fillOpacity={0} stroke="#3f3d56" />
    </g>
    <g>
      <use xlinkHref="#Q" fill="#57b894" />
      <use xlinkHref="#Q" fillOpacity={0} stroke="#000" strokeOpacity={0} />
    </g>
    <g>
      <use xlinkHref="#R" fillOpacity={0} />
      <use xlinkHref="#R" fillOpacity={0} stroke="#3f3d56" />
    </g>
    <g>
      <use xlinkHref="#S" fill="#2f2e41" />
      <use xlinkHref="#S" fillOpacity={0} stroke="#000" strokeOpacity={0} />
    </g>
    <g>
      <use xlinkHref="#T" fill="#2f2e41" />
      <use xlinkHref="#T" fillOpacity={0} stroke="#000" strokeOpacity={0} />
    </g>
    <g>
      <use xlinkHref="#U" fill="#2f2e41" />
      <use xlinkHref="#U" fillOpacity={0} stroke="#000" strokeOpacity={0} />
    </g>
    <g>
      <use xlinkHref="#V" fillOpacity={0} />
      <use xlinkHref="#V" fillOpacity={0} stroke="#3f3d56" />
    </g>
    <g>
      <use xlinkHref="#W" fillOpacity={0} />
      <use xlinkHref="#W" fillOpacity={0} stroke="#3f3d56" />
    </g>
    <g>
      <use xlinkHref="#X" fillOpacity={0} />
      <use xlinkHref="#X" fillOpacity={0} stroke="#3f3d56" />
    </g>
    <g>
      <use xlinkHref="#Y" fill="#fff" />
      <use xlinkHref="#Y" fillOpacity={0} stroke="#000" strokeOpacity={0} />
    </g>
    <g>
      <use xlinkHref="#Z" fill="#fff" />
      <use xlinkHref="#Z" fillOpacity={0} stroke="#000" strokeOpacity={0} />
    </g>
    <g>
      <use xlinkHref="#aa" fill="#fff" />
      <use xlinkHref="#aa" fillOpacity={0} stroke="#000" strokeOpacity={0} />
    </g>
    <g>
      <use xlinkHref="#ab" fill="#991a5f" />
      <use xlinkHref="#ab" fillOpacity={0} stroke="#000" strokeOpacity={0} />
    </g>
    <g>
      <use xlinkHref="#ac" fill="#991a5f" />
      <use xlinkHref="#ac" fillOpacity={0} stroke="#000" strokeOpacity={0} />
    </g>
  </svg>
);

export default NotFound;
